
// import Notes from "./Notes";
import React from "react";
import Navbar from "./Navbar";
import AddNote from "./AddNote";


export const FormList = (props) => {
 const  {showAlert}= props
  return (
    <div>
       <Navbar />
       
      {/* <Notes showAlert={showAlert}/> */}
      <AddNote showAlert={showAlert} />

    </div>
   
  );
};
