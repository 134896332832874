import React from "react";
import { Link, useLocation  } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import NoteState from "../context/notes/NoteState";
// import 'bootstrap/dist/css/bootstrap.min.css';
const Navbar = () => {
  const navigate = useNavigate()
const handleLogout =()=>{

  localStorage.removeItem('token')
  navigate('/login')
}

  let location = useLocation();
  // useEffect(() => {
  //   console.log(location.pathname);
  // }, [location])
  return (
    <div name="top">
      <NoteState>

      
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <li className="navbar-brand-nav">
         <h1>
          A1 Nataraj Academy
          </h1> 
        </li>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
           {!localStorage.getItem('token')?  <li className={`nav-item ${location.pathname==="/"?"active": ""}`}>
           <Link className="nav" to="#">
              <span className="sr-only"></span>
              </Link>   
            </li>:
            
            <li className="navbar-text " >
              
              <Link className="nav-link" to="/formList">
              Form List  <span className="sr-only">(current)</span>
              </Link>
              
            <Link className="nav-link " to="/forRegistration">
              Registration<span className="sr-only">(current)</span>
              </Link>
            </li> }
           
          </ul>
          
         {!localStorage.getItem('token')? <form className="d-flex">
           <Link className="btn btn-primary mx-1" to="/login" role="button">login</Link>
           <Link className="btn btn-primary mx-1" to="/signup"  role="button">Signup</Link>
          </form>:  
          <button onClick={handleLogout} className="btn btn-primary">Logout</button>
          }
        </div>
      </nav>
      
      {/* {localStorage.getItem('token').name} */}
      </NoteState>
    </div>
  );
};

export default Navbar;
