import React, {useState} from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import Navbar from "./Navbar";

const Login = (props) => {
    const [credentials, setCredentials] = useState({email:"", password:""})
     const navigate = useNavigate()
    const handleSubmit = async (e)=>{
        e.preventDefault()
        const response = await fetch("http://localhost:8800/api/auth/login", { 
            method: 'POST',   
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: credentials.email, password: credentials.password}) 
    })
    const json = await response.json()
    console.log(json);
    if (json.success){
      // Save the auth token and redirect
    localStorage.setItem('token', json.authtoken)
    
    props.showAlert("Logged in Successfully", "success")
     
    navigate("/forRegistration")
    }
    else{
        // props.showAlert("Invalid Details", "danger")
        Swal.fire({
              icon: "error",
              title: "Soryy...",
              text: "You can't login witout permition",
              footer: '<a href="/">Go To Home</a>'
            });
    }
}

//  const navigate = useNavigate()

//   const signupClick=()=>{

//     navigate('/signup')
// }

const onChange = (e)=>{
    setCredentials({...credentials, [e.target.name]:e.target.value})
}


    return (
      <>
           <Navbar />
           {/* <h3>
      <a href="/">Go to Back Home Page</a>
      </h3>  */}
      
        <div className='mt-2'>
          <h2 className='my-3'>Login Here </h2>
           <form onSubmit={handleSubmit}>
  <div className="form-group">
    <label htmlFor="email">Email address</label>
    <input type="email" className="form-control" value={credentials.email} onChange={onChange} name='email' id="email" aria-describedby="emailHelp" placeholder="Enter email" />
    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div className="form-group">
    <label htmlFor="password">Password</label>
    <input type="password" className="form-control"name='password' onChange={onChange} value={credentials.password} id="password" placeholder="Password" />
  </div>
  <button type="submit" className="btn btn-primary">Submit</button>
 {/* <h1>
   <link rel="stylesheet" href="/signup" />Registration
  </h1> */}
  {/* <button onClick={signupClick} className="pushable m-10">
              <span  className="front">Registration</span>
              </button> */}
  
</form>
        </div>
    </>
    )
}

export default Login
