import React, { useState, useEffect, useContext } from "react";
import MidButton from "./MidButton";
// import { sliderData } from "../../DataForPage/dummyData";
import { sliderData1 } from "../../DataForPage/dummyData";
import TipseGymnastics from "./BestGymnasticsAcademyInVikasPuri";
import { motion } from "framer-motion";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";

const GymnasticsAcademyInVikasPuri = () => {
  // const buttons = [
  //   {
  //     id: "1",
  //     name: "Hoop",
  //   },
  //   {
  //     id: "2",
  //     name: "Silk",
  //   },
  //   {
  //     id: "3",
  //     name: "Pole",
  //   },
  // ]

  const buttons1 = [
    {
      id: "1",
      name: "Gym-1",
    },
    {
      id: "2",
      name: "Gym-2",
    },
    {
      id: "3",
      name: "Gym-3",
    },
    // {
    //   id: "1",
    //   name: "Gym-1",
    // },
    // {
    //   id: "2",
    //   name: "Gym-2",
    // },
    // {
    //   id: "3",
    //   name: "Gym-3",
    // },
  ]
  const { theme } = useContext(ThemeBgContext);
  // const [selected, setSelected] = useState("1");
  // const [click, setClick] = useState(false);
  // const [data, setData] = useState([]);

  const [selected1, setSelected1] = useState("1");
  const [click1, setClick1] = useState(false);
  const [data1, setData1] = useState([]);

  // const filterData = (button) => {
  //   const filter = sliderData.filter((item) => item.id === button);
  //   setData(filter);
  //   setSelected(button);
  //   setClick(true);
  // };

  // useEffect(() => {
  //   const putData = (data) => {
  //     setData(data.filter((item) => item.id === "1"));
  //   };
  //   putData(sliderData);
  // }, []);

  const filterData1 = (button) => {
    const filter1 = sliderData1.filter((item) => item.id === button);
    setData1(filter1);
    setSelected1(button);
    setClick1(true);
  };
  useEffect(() => {
    const putData1 = (data1) => {
      setData1(data1.filter((item) => item.id === "1"));
    };
    putData1(sliderData1);
  }, []);

  return (
    <div className="mx-auto pt-12 pb-56" id="gymnastics">
      <div className="sm:grid-cols-1 grid grid-cols-2 justify-items-center items-center">
        <motion.div
          className="sm:pt-10 sm:w-full sm:pl-4 w-4/5 mx-auto pl-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <h2
            className={
              theme === "light"
                ? "sm:text-3xl sm:w-full w-96 text-6xl font-bold font-inter no-underline aligin-middle tracking-wide normal-case leading-none text-dark"
                : "sm:text-3xl sm:w-full w-96 text-6xl font-bold font-inter no-underline aligin-middle tracking-wide normal-case leading-none text-white"
            }
          >
             Gymnastics
          </h2>
          <MidButton
            buttons={buttons1}
            filter={filterData1}
            selected={selected1}
          ></MidButton>
           {/* <MidButton
            buttons={buttons}
            filter={filterData1}
            selected={selected1}
          ></MidButton> */}
        </motion.div>


        <div className="ml-10 pt-32 pb-4 display-block relative mx-auto w-8/12 rounded-xl drop-shadow-2xl">
          <TipseGymnastics data={data1} click={click1}></TipseGymnastics>
        </div>
        

{/* -------------------------------------------Aerial Gymanstics------------------------------------------------------------------ */}


        {/* <motion.div
          className="sm:pt-10 mt-[10vw] sm:w-full sm:pl-4 w-4/5 mx-auto pl-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <h2
            className={
              theme === "light"
                ? "sm:text-3xl sm:w-full w-96 text-6xl font-bold font-inter no-underline aligin-middle tracking-wide normal-case leading-none text-dark"
                : "sm:text-3xl sm:w-full w-96 text-6xl font-bold font-inter no-underline aligin-middle tracking-wide normal-case leading-none text-white"
            }
          >
            Aerial Gymnastics
          </h2>
          <MidButton
            buttons={buttons}
            filter={filterData}
            selected={selected}
          ></MidButton>
        </motion.div>
        <div className="ml-20 pb-4 pt-32  mt-20 relative mx-auto w-8/12 rounded-xl drop-shadow-2xl">
          <TipseGymnastics data={data} click={click}></TipseGymnastics>
        </div> */}
      </div>
    </div>
  );
};

export default GymnasticsAcademyInVikasPuri;
