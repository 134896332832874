import React from "react";
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./App.css";
import Home from "./Components/AllComponents/Home";
import ThemeContext from "./Components/ContextWrapper/ThemeContext";
import About from "./Components/AboutUs/BestDanceAcademyForKidsInVikasPuriJanakPuri";
import AryanTongariya from "./Components/aryan/AryanTongariya";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./Components/Footer/AerialClassesInDelhi";
// import { Home } from "./Components/Home";
import NoteState from "./context/notes/NoteState";
import  Alert  from "./Components/Alert";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import {useState} from 'react'
// import { FormList } from "./Components/FormList";
import 'bootstrap/dist/css/bootstrap.min.css';

// import WeddingModal from "./Components/wedingModal/WeddingModal"
// import { SliderData } from "./Components/wedingModal/SliderData";
// import Wedding from "./Components/Wedding/Wedding";
import WeddinhWrapper from "./Components/Wedding/BestWeddingDanceChoreographerInDelhiNcr";
import ErrorPage from "./Components/error page/ErrorPage";
import { HomeForm } from "./Components/HomeForm";
import { FormList } from "./Components/FormList";
import Notes from "./Components/Notes";

function App() {

  const [inputValue, setInputValue] = useState('')

  const showAlert = (message, type) => {
    // withReactContent(Swal).fire({
    //   title: <i>Input something</i>,
    //   // input: 'text',
    //   inputValue,
    //   preConfirm: () => {
    //     setInputValue(Swal.getInput()?.value ||{
    //       msg: message,
    //       type:type,
    //     })
    //   },
    // })
    Swal.fire({
      title: message,
      icon: type,
      draggable: true,
    
      preConfirm: () => {
            setInputValue()
           
          },
          
    });
  }

 

  // const [alert, setAlert] = useState(null);

  // const showAlert = (message, type)=>{
  //   setAlert({
  //     msg: message,
  //     type:type
  //   })
  //   setTimeout(()=>{
  //     setAlert(null)
  //   },2000)
  // }
  return (  
    <>
   
    <div className="App">
         {/* <div className="container"> */}
         
         <Alert className="alert" inputValue={inputValue}/> 
         <NoteState>
         <ThemeContext>
        {/* <Home></Home> */}
      
        <BrowserRouter>
      <Routes>
      <Route path='/' element={ <Home/>} />
      <Route path='/notes' element={ <Notes/>} />
    <Route path='/BestDanceAcademyForKids' element={ <About/>} />
    <Route path='/error' element={ <ErrorPage/>} />
    <Route path='/aryanTongariya' element={ <AryanTongariya/>} />
    {/* <Route path='/wedding' element={<WeddingModal slides={SliderData}  /> } /> */}
    <Route path='/BestWeddingDanceChoreographerInDelhiNcr' element={<WeddinhWrapper/>} />
    <Route path='/formList' element={<HomeForm showAlert={showAlert}/>} />
    <Route path='/forRegistration' element={<FormList showAlert={showAlert}/>} />
    <Route path='/login' element={<Login showAlert={showAlert}/>} />
    <Route path='/signup' element={<Signup showAlert={showAlert}/>} />
      </Routes>
      </BrowserRouter>
      <hr />
      <Footer></Footer> 
    </ThemeContext>
    {/* </div> */}
      </NoteState>

      
    </div>
    </>
  );
}

export default App;
